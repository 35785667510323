/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Row,
  Col,
  Tooltip,
} from 'antd';
import {
  formatString,
  interestRateByPortfolio,
} from 'utils';
import {
  Card,
  Information,
} from 'components/gyramais';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';

import './styles.less';

/**
 *
 * @param {{
 *  loan: any
 *  onChangeValues?: void
 *  disabled?: boolean
 *  type: 'danger' | 'warning' | 'success'
 *  className?: string
 *  maxInstallmentsCount?: number
 * }} props
 * @returns {React.FC}
 */
const PreApprovedBadesulCard = ({
  loan = {},
  className,
}) => {
  const { width } = useWindowDimensions();

  const [paymentDay, setPaymentDay] = useState(0);

  const paymentDaysOptions = useMemo(() => [1, 5, 10, 15, 20]);

  useEffect(() => {
    if (loan) {
      const date = loan.firstPaymentDate && new Date(loan.firstPaymentDate);
      if (date) {
        date.setHours(date.getHours() + 3);

        const paymentDayIndex = paymentDaysOptions.indexOf(date.getDate());
        setPaymentDay(paymentDayIndex);
      }
    }
  }, [loan?.id]);

  return (
    <Card className={`pre-approved-card ${className || ''}`}>
      <Row gutter={[56, 16]} align="middle">
        <Col xs={24} sm={24} md={24}>
          <Row align="middle" gutter={[16, 0]}>
            <Col xs={24} sm={24} md={24}>
              <Row gutter={width > 768 ? [32, 24] : [8, 8]}>
                <Col xs={24} sm={24} md={24}>
                  <Information
                    label="Total pré-aprovado"
                    value={formatString(loan?.finalAmount, 'currency')}
                    type=""
                  />
                </Col>

                <Col xs={24} sm={24} md={24}>
                  <Information
                    label="Taxa"
                    value={(
                      <div>
                        <Tooltip title="Variação diária da taxa">
                          {loan?.additionalInfo?.taxRateType}* + {interestRateByPortfolio[loan?.portfolioName]} a.a.
                        </Tooltip>
                      </div>
                    )}
                  />
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Information
                    label="Parcelas"
                    value="4 trimestrais de carência e 36 mensais de amortização"
                  />
                </Col>
                {loan?.type?.value !== 'receivables-guarantee' && (
                  <Col xs={24} sm={24} md={24}>
                    <Information
                      label="Vencimento"
                      value={`Todo dia ${paymentDaysOptions[paymentDay]}`}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default PreApprovedBadesulCard;
